/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import bg0 from "../../assets/images/bg/00.jpg";
import bg1 from "../../assets/images/bg/02.jpg";
import bg2 from "../../assets/images/bg/05.jpg";
import logoIcon from "../../assets/images/logo-icon.png";

import Navbar from "../../components/navbar";
import MarcarConsulta from "../../components/MarcarConsulta";
import AboutImage from "../../components/aboutImage";
import CtaThree from "../../components/cta/ctaThree";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

import { SnackbarProvider, enqueueSnackbar, useSnackbar } from 'notistack';

import {
  RiEyeFill, RiPsychotherapyFill, RiStethoscopeFill, RiCapsuleFill, RiMicroscopeFill, RiPulseFill, RiEmpathizeFill, RiMindMap, RiMentalHealthLine, RiAppleLine, RiInfraredThermometerLine, RiCapsuleLine, GrUserPolice, RiMicroscopeLine, TbAmbulance, RiShieldCrossLine, RiBriefcase4Line, FiAirplay, MdOutlineFlip, PiTelegramLogoBold, RiMailStarLine, RiHourglassLine, RiHeartLine, RiHeartPulseLine, RiStethoscopeLine, RiVirusLine, RiDossierLine, RiEye2Line, FiUser, FiMail, VscBook, MdFormatItalic, GiMedicalDrip, FiMapPin, FiBriefcase, LuFileCheck, MdOutlineStorefront, LuAward, LiaFileInvoiceDollarSolid, FiThumbsUp, FiPhone, RiArrowRightLine,
  RiMapPin2Line,
  FiFacebook,
  FiLinkedin,
  FiGithub,
  MdOutlineEventNote,
  FiCalendar,
  FiClock,
  FiHeart, FiEye, RiUser2Line, FiTwitter
} from "../../assets/icons/vander";

import {
  ctaData,
  ctaDataMarcacaoConsulta,
  medicalServices,
  partners,
  doctorData,
  contactData,
} from "../../data/data";

import {
  unidadeHospitalar,
  blogData,
  commentData,
  recentPost,
} from "../../data/data";

import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

import AuthUser from "../../components/AuthUser";
import Spinner from "../../components/Spinner";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faCheckCircle,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import ServicosIcons from "../../components/ServicosIcons";


const icons = [
  { nome: 'olhos', icon: RiEyeFill },
  { nome: 'cabeça', icon: RiPsychotherapyFill },
  { nome: 'ostetoscopio', icon: RiStethoscopeFill },
  { nome: 'capsula', icon: RiCapsuleFill },
  { nome: 'microscopio', icon: RiMicroscopeFill },
  { nome: 'cardiologia', icon: RiPulseFill },
  { nome: 'coracao', icon: RiEmpathizeFill },
  { nome: 'celulas', icon: RiMindMap },
  { nome: 'cerebro', icon: RiMentalHealthLine },
  { nome: 'apple', icon: RiAppleLine },
  { nome: 'fratura', icon: RiInfraredThermometerLine },
  { nome: 'calendar', icon: FiCalendar },
  { nome: 'doctor', icon: GrUserPolice },
];

const IndexThree = () => {

  const { http, url_root, logoTipo, verificarPermissao } = AuthUser();

  const getIconByName = (name) => {
    const iconObject = icons.find(icon => icon.nome == name);
    return iconObject ? iconObject.icon : null;
  };

  let IconeCalendar = getIconByName("calendar");

  let settings = {
    container: ".slider-range-three",
    items: 14,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 24,
    responsive: {
      992: {
        items: 3,
      },

      767: {
        items: 2,
      },

      320: {
        items: 1,
      },
    },
  };

  let params = useParams();
  let id = params.id;
  let data = blogData.find((blog) => blog.id === parseInt(id));

  const [unidades, setUnidades] = useState([]);
  const [doctores, setDoctores] = useState([]);
  const [directores, setDiretores] = useState([]);

  const [bgBackup, setBG] = useState(bg1);
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Lógica para carregar cursos apenas se a permissão permitir

    const fetchData = async () => {
      try {
        // Chama a função assíncrona

        await getInfo();

      } catch (error) {
        setLoading(false);
        console.error('Erro ao obter info:', error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();

  }, []);


  useEffect(() => {
    console.log('Unidades:', unidades);
    setUnidades(info[0]?.unidades?.slice(0, 14) || []);
    setDoctores(info[0]?.doctores?.slice(0, 14) || []);
    setDiretores(info[0]?.directores?.slice(0, 14) || []);
  }, [info]);


  const getInfo = async () => {

    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/site-pagina/1');

      console.log("info :");
      console.log(res.data);

      setInfo(res.data);

      console.log('Doctores');
      console.log(info[0]?.doctores);
      console.log('Consultas');
      console.log(info[0]?.consultas);

      setBG(url_root + "/storage/" + info[0]?.banner?.imagem);

      setUnidades(info[0]?.unidades?.slice(0, 14) || []);
      setDoctores(info[0]?.doctores?.slice(0, 14) || []);
      setDiretores(info[0]?.directores?.slice(0, 14) || []);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };


  const [consulta_selecionada, setConsultaSelecionada] = useState(null);
  const [especialista, setDoctorSelecionado] = useState(null);

  const [filteredDoctores, setFilteredDoctores] = useState([]);

  /*
  const filterDoctores = (consultaId) => {
    if (consultaId > 0) {

      const relatedEspecialidades = info[0]?.especialistas.filter(especialidade => especialidade.curso_id === parseInt(consultaId));

      const doctorIds = relatedEspecialidades.map(especialidade => especialidade.formador_id);
      const newFilteredDoctores = info[0]?.doctores.filter(doctor => doctorIds.includes(doctor.id));

      setFilteredDoctores(newFilteredDoctores);

    } else {
      setFilteredDoctores(info[0]?.doctores);
    }
  }*/


  const filterDoctores = (consultaId) => {
  
      setFilteredDoctores(info[0]?.doctores);
      
  }

  const handleSelectConsultaChange = (valor) => {
    setConsultaSelecionada(valor);
    filterDoctores(valor);
  }

  const handleSelectDoctorChange = (valor) => {
    setDoctorSelecionado(valor);
  }

  const renderOptionConsultas = (consultas) => {
    return consultas?.map((consulta) => (
      <React.Fragment key={consulta.id}>
        <option value={consulta.id}>{consulta.designacao.toUpperCase()}</option>
      </React.Fragment>
    ));
  };


  const renderOptionDoctores = (doctores) => {
    return doctores?.map((doctor) => (
      <React.Fragment key={doctor.id}>
        <option value={doctor.id}>{doctor.name.toUpperCase()}</option>
      </React.Fragment>
    ));
  };

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-left nav-light" containerClass="container"
      />


      {info[0]?.banner != null ? <>
        <section
          className="bg-half-170 s-banner-principal d-table w-100"
          id="home"
          style={{ backgroundImage: `url(${url_root + "/storage/" + info[0]?.banner?.imagem})` }}
        >
          <div className="bg-overlay bg-overlay-dark bg-banner-principal"> </div>{" "}
          <div className="container">
            <div className="row justify-content-center mt-5">
              <div className="col-xl-10">
                <div className="heading-title text-center">
                  <img
                    src={logoIcon}
                    className="logo-heartbeat"
                    height="50"
                    alt=""
                  />
                  <h4 className="heading fw-bold text-white title-dark mt-3 mb-4">
                    {" "}
                    {info[0]?.banner?.designacao}{" "}
                  </h4>{" "}
                  <p className="para-desc mx-auto text-white-50 mb-0">
                    {" "}
                    <Link to="/jornada-cientifica">{info[0]?.banner?.descricao}</Link>
                    {/* <Link to="/jornada-cientifica">A ciência te espera! Participe da nossa jornada cientifica, inscreva-se agora!</Link>{" "} */}
                  </p>
                  <div className="mt-4 pt-2">
                    <form className="rounded text-start shadow p-4 bg-white-50">
                      <div className="row align-items-center">
                        <div className="col-md">
                          <div
                            className="input-group bg-white border rounded"
                            style={{ opacity: "0.7" }}
                          >
                            <span className="input-group-text border-0">
                              {" "}
                              <RiMapPin2Line className="text-primary h5 fw-normal mb-0" />{" "}
                            </span>{" "}
                            <select
                              onChange={(e) => handleSelectConsultaChange(e.target.value)}
                              value={consulta_selecionada}
                              className="form-select form-control"
                            >
                              <option value={-1}>Selecione o tipo de consulta</option>
                              {renderOptionConsultas(info[0]?.consultas)}

                            </select>
                          </div>{" "}
                        </div>
                        <div className="col-md mt-4 mt-sm-0">
                          <div
                            className="input-group bg-white border rounded"
                            style={{ opacity: "0.7" }}
                          >
                            <span className="input-group-text border-0">
                              {" "}
                              <RiUser2Line className="text-primary h5 fw-normal mb-0" />{" "}
                            </span>{" "}
                            <select
                              onChange={(e) => handleSelectDoctorChange(e.target.value)}
                              value={especialista}
                              className="form-select form-control"
                            >
                              <option value={-1}>Selecione o especialista</option>
                              {renderOptionDoctores(filteredDoctores)}

                            </select>
                          </div>{" "}
                        </div>
                        <div className="col-md-auto mt-4 mt-sm-0">
                          <div className="d-grid d-md-block">
                            <Link to={"/doctor-profile/" + especialista} className="btn btn-primary">
                              {" "}
                              Informação do Especialista{" "}
                            </Link>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </form>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>
      </> : <></>}



      <section className="section py-5 bg-light">
        <div className="container">
          <div className="row">
            {" "}
            {info[0]?.paragrafos?.slice(0, 3)?.map((item, index) => {

              return (
                <div className="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0" key={index}>
                  <div className="d-flex">

                    {index == 0 ? <><FontAwesomeIcon icon={faList} className="mb-0 text-primary" size="2x" /></> : index == 1 ? <><FontAwesomeIcon icon={faCalendar} className="mb-0 text-primary" size="2x" /></> : <><FontAwesomeIcon icon={faCheckCircle} className="mb-0 text-primary" size="2x" /></>}


                    <div className="ms-3 ms-lg-4">
                      <h5> {item.titulo} </h5>{" "}
                      <p className="text-muted mb-0"> {item.descricao} </p>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>
              );
            })}{" "}
          </div>{" "}
        </div>{" "}
      </section>


      {/* UNIDADES SANITARIAS INICIO */}

      {unidades ? <>
        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mb-2 pb-2 text-center">
                <span className="badge rounded-pill bg-soft-primary mb-3">
                  {" "}
                  Unidades{" "}
                </span>{" "}
                <h4 className="title mb-2"> Unidade Sanitarias Da Região </h4>{" "}
              </div>{" "}
            </div>{" "}
          </div>
          <div className="row">
            <div className="col-lg-12 mt-2 pt-2">

              <div className="slider-range-three">

                <TinySlider settings={settings}>

                  {" "}

                  {unidades?.map((item, index) => {
                    return (
                      <div className="col-lg-3 col-md-6 col-12 mt-4 pt-2" key={index}>
                        <div className="card shop-list border-0">

                          <div className="shop-image position-relative overflow-hidden rounded shadow">
                            <Link to={"/pharmacy-product-detail/"+item?.id}><img src={url_root + "/storage/" + item.imagem} className="img-fluid" alt="" /></Link>
                            <ul className="list-unstyled shop-icons">
                              <li><Link to="#" className="btn btn-icon btn-pills btn-soft-danger"><FiHeart className="icons" /></Link></li>
                              <li className="mt-2"><Link to="/pharmacy-product-detail" className="btn btn-icon btn-pills btn-soft-primary"><FiEye className="icons" /></Link></li>
                            </ul>
                          </div>

                          <div className="card-body content text-center">
                            <Link
                              to={"/pharmacy-product-detail/"+item?.id}
                              className="title text-dark h5 d-block mb-0"
                            >
                              {" "}
                              {item.designacao}{" "}
                            </Link>{" "}
                            <small className="text-muted speciality">
                              {" "}
                              {/* {item.price} */}
                              {" "}
                            </small>{" "}
                          </div>{" "}

                        </div>
                      </div>
                    );
                  })}{" "}

                </TinySlider>{" "}

              </div>{" "}


            </div>{" "}
          </div>{" "}
        </div>
      </> : <></>}

      {/* UNIDADES SANITARIAS FIM */}

      <section className="section pb-0">
        <div className="container">
          <div className="row align-items-center">
            <AboutImage imagem={url_root + "/storage/" + info[0]?.paragrafos[3]?.imagem} videoId={info[0]?.paragrafos[3]?.icone} colClass="col-lg-5 col-md-6" />
            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <span className="badge rounded-pill bg-soft-primary">
                  {" "}
                  Sobre o HRL{" "}
                </span>{" "}
                <h4 className="title mt-3 mb-4">
                  {" "}
                  {info[0]?.paragrafos[3]?.titulo}{" "}
                </h4>{" "}
                <p className="para-desc text-muted">
                  {" "}
                  {info[0]?.paragrafos[3]?.descricao}.{" "}
                </p>{" "}
                <p className="para-desc text-muted">
                  {" "}
                  {info[0]?.paragrafos[4]?.descricao}.{" "}
                </p>{" "}
                <div className="mt-4">
                  <Link to="/aboutus" className="btn btn-soft-primary">
                    {" "}
                    Ler mais{" "}
                  </Link>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mb-4 pb-2 text-center">
                <span className="badge rounded-pill bg-soft-primary mb-3">
                  {" "}
                  {info[0]?.paragrafos[5]?.titulo}{" "}
                </span>{" "}
                <h4 className="title mb-4"> {info[0]?.paragrafos[5]?.descricao} </h4>{" "}
                <p className="text-muted mx-auto para-desc mb-0">
                  {" "}
                  {info[0]?.paragrafos[6]?.descricao}{" "}
                </p>{" "}
              </div>{" "}
            </div>{" "}
          </div>

          <ServicosIcons medicalServices={info[0]?.consultas} />

        </div>
        <div className="container-fluid mt-100 mt-60">
          <div
            className="py-5 rounded"
            style={{ backgroundImage: `url(${url_root + "/storage/" + info[0]?.paragrafos[7]?.imagem})` }}
          >

            {doctores ? <><CtaThree doctores={doctores} /></> : <></>}


          </div>{" "}
        </div>{" "}
      </section>


     

      <section className="section mt-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-4"> {info[0]?.paragrafos[8]?.titulo} </h4>{" "}
                <p className="text-muted mx-auto para-desc mb-0">
                  {" "}
                  {info[0]?.paragrafos[8]?.descricao}{" "}
                </p>{" "}
              </div>{" "}
            </div>{" "}
          </div>
          <div className="row align-items-center">
            {" "}
            {info[0]?.directores?.slice(0, 4).map((item, index) => {
              return (
                <div
                  className="col-xl-3 col-lg-3 col-md-6 mt-4 pt-2"
                  key={index}
                >
                  <div className="card team border-0 rounded shadow overflow-hidden">
                    <div className="team-img position-relative">
                      <img src={url_root + "/storage/" + item?.fotografia} className="img-fluid" alt="" />
                      <ul className="list-unstyled team-social mb-0">
                        <li>
                          {" "}
                          <Link
                            to="#"
                            className="btn btn-icon btn-pills btn-soft-primary"
                          >
                            {" "}
                            <FiFacebook className="icons" />{" "}
                          </Link>
                        </li>
                        <li className="mt-2 ms-0">
                          {" "}
                          <Link
                            to="#"
                            className="btn btn-icon btn-pills btn-soft-primary"
                          >
                            {" "}
                            <FiLinkedin className="icons" />{" "}
                          </Link>
                        </li>
                        <li className="mt-2 ms-0">
                          {" "}
                          <Link
                            to="#"
                            className="btn btn-icon btn-pills btn-soft-primary"
                          >
                            {" "}
                            <FiGithub className="icons" />{" "}
                          </Link>
                        </li>
                        <li className="mt-2 ms-0">
                          {" "}
                          <Link
                            to="#"
                            className="btn btn-icon btn-pills btn-soft-primary"
                          >
                            {" "}
                            <FiTwitter className="icons" />{" "}
                          </Link>
                        </li>
                      </ul>{" "}
                    </div>{" "}
                    <div className="card-body content text-center">
                      <Link
                        to={"/doctor-profile/"+item?.id}
                        className="title text-dark h5 d-block mb-0"
                      >
                        {" "}
                        {item?.name}{" "}
                      </Link>{" "}
                      <small className="text-muted speciality">
                        {" "}
                        {item?.nivel_academico}{" "}
                      </small>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>
              );
            })}{" "}
          </div>{" "}
        </div>




        <div className="container mt-100 mt-60">
          <div className="row align-items-lg-end">
            <div className="col-md-6">
              <div className="me-xl-3">
                <div className="section-title mb-4 pb-2">

                  <IconeCalendar className="text-primary h2" />
                  <h4 className="title mb-4"> {info[0]?.marcar_consulta[0]?.titulo} </h4>{" "}
                  <p className="text-muted para-desc mb-0">
                    {" "}
                    {info[0]?.marcar_consulta[0]?.descricao}{" "}
                  </p>{" "}
                </div>{" "}
                {info[0]?.marcar_consulta?.slice(1, 4).map((item, index) => {
                  let Icon = getIconByName(item?.icone);
                  return (
                    <div
                      className="features feature-bg-primary d-flex card flex-row p-4 rounded-md shadow position-relative overflow-hidden mt-4"
                      key={index}
                    >
                      <Icon className="icons display-3 mb-0 text-primary" />
                      <div className="ms-3">
                        <h5 className="titles"> {item.titulo} </h5>{" "}
                        <p className="text-muted para mb-0"> {item.descricao} </p>{" "}
                      </div>{" "}
                      <div className="big-icon">
                        <Icon />
                      </div>{" "}
                    </div>
                  );
                })}{" "}
              </div>{" "}
            </div>

            <MarcarConsulta doctores={info[0]?.doctores} consultas={info[0]?.consultas} especialidades={info[0]?.especialistas} unidades={info[0]?.unidades} />


          </div>{" "}
        </div>{" "}





        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center mb-4 pb-2">
                <span className="badge rounded-pill bg-soft-primary mb-3">{info[0]?.paragrafos[9]?.titulo}</span>
                <h4 className="title mb-4">{info[0]?.paragrafos[9]?.descricao}</h4>
              </div>
            </div>
          </div>

          <div className="row">
            {info[0]?.noticias?.slice(0, 3).map((item, index) => {
              return (
                <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2" key={index}>
                  <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                    <img src={url_root + "/storage/" + item.imagem} className="img-fluid" alt="" />
                    <div className="card-body p-4">
                      <ul className="list-unstyled mb-2 ">
                        <li className="list-inline-item text-muted small me-3 d-inline-flex align-items-center"><FiCalendar className="text-dark h6 me-1 mb-0" />{item.descricao}</li>
                        <li className="list-inline-item text-muted small d-inline-flex align-items-center"><FiClock className="text-dark h6 me-1 mb-0 " />5 min</li>
                      </ul>
                      <Link to={"/blog-detail/" + item?.id} className="text-dark title h5">{item.titulo}</Link>
                      <div className="post-meta d-flex justify-content-between mt-3">
                        <ul className="list-unstyled mb-0">
                          <li className="list-inline-item me-2 mb-0"><Link to="#" className="text-muted like"><i className="mdi mdi-heart-outline me-1"></i>{item.like}</Link></li>
                          <li className="list-inline-item"><Link to="#" className="text-muted comments"><i className="mdi mdi-comment-outline me-1"></i>{item.comment}</Link></li>
                        </ul>
                        <Link to={"/blog-detail/" + item?.id} className="link">Ler mais <i className="mdi mdi-chevron-right align-middle"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

      </section>{" "}
      <Footer footer={info[0]?.footer} consultas={info[0]?.consultas} />
      <ScrollTop />

      {
        loading ? <>
          <Spinner />
        </> : <></>
      }

    </>
  );
}

export default function WrappedFooterIndex() {
  return (
    <SnackbarProvider>
      <IndexThree />
    </SnackbarProvider>
  );
}

