import React, { useState } from "react";
import { enqueueSnackbar, useSnackbar } from "notistack";

import AuthUser from "./AuthUser";
import Spinner from "./Spinner";

export default function InscricaoJornadaCientifica({ cursos }) {
  const { http, url_root, logoTipo, verificarPermissao } = AuthUser();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar(); // Extraindo a função corretamente

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [genero, handleSelectGenerorChange] = useState(0);
  const [cargo, handleSelectFuncaoChange] = useState(0);
  const [telefone, setTelefone] = useState("");
  const [nome, setNome] = useState("");
  const [data_hoje, setDataHoje] = useState();
  const [endereco, setEndereco] = useState("");
  const [instituicao, setInstituicao] = useState("");
  const [area, setArea] = useState("");
  const [quantidade, setQuantidade] = useState(0);
  const [nacionalidade, setNacionalidade] = useState("Angolana");

  const [cursoSelecionado, setCursoSelecionado] = useState(-1);
  const [cursosAdicionados, setCursosAdicionados] = useState([]);

  // Adiciona um curso à tabela com o preço correto conforme a função selecionada
  const adicionarCurso = () => {
    if (cursoSelecionado == -1) {
      enqueueSnackbar("Por favor, selecione uma abordagem.", { variant: "error" });
      return;
    }

    if (cargo == 0) {
      enqueueSnackbar(
        "Por favor, selecione sua função antes de adicionar um curso.",
        { variant: "error" }
      );
      return;
    }

    const curso = cursos.find((c) => c.id == parseInt(cursoSelecionado));

    if (!curso) return;

    // Determinar o preço do curso com base na função escolhida
    let precoCurso = curso.preco; // Valor padrão

    switch (cargo) {
      case "Médico(a)":
        precoCurso = curso.preco_medico;
        break;
      case "Enfermeiro(a)":
        precoCurso = curso.preco_enfermeiro;
        break;
      case "Estudante":
        precoCurso = curso.preco_estudante;
        break;
      default:
        precoCurso = curso.preco;
    }

    // Verificar se o curso já foi adicionado
    const existente = cursosAdicionados.find((item) => item.id == curso.id);

    if (existente) {
      // Atualizar a quantidade
      setCursosAdicionados((prev) =>
        prev.map((item) =>
          item.id == curso.id
            ? { ...item, quantidade: item.quantidade + quantidade }
            : item
        )
      );
    } else {
      // Adicionar novo curso com preço ajustado
      setCursosAdicionados([
        ...cursosAdicionados,
        { ...curso, preco: precoCurso, quantidade },
      ]);
    }
  };

  // Remove um curso da tabela
  const removerCurso = (id) => {
    setCursosAdicionados(cursosAdicionados.filter((curso) => curso.id !== id));
  };

  const handleInscrever = async () => {
    try {
      if (nome.length > 2) {
        // Verificar se o e-mail é válido
        if (!isEmailValid(email)) {
          enqueueSnackbar("Por favor, forneça um e-mail válido.", {
            variant: "error",
          });
          return;
        }

        if (!isDateValid(data_hoje)) {
          enqueueSnackbar("Por favor, digite uma data de nascimento válida.", {
            variant: "error",
          });
          return;
        }

        if (genero == 0) {
          enqueueSnackbar("Por favor, selecione o gênero.", {
            variant: "error",
          });
          return;
        }

        if (cursoSelecionado == -1) {
          enqueueSnackbar("Por favor, selecione um curso.", {
            variant: "error",
          });
          return;
        }

        if (telefone.length < 9) {
          enqueueSnackbar("Por favor, digite um telefone válido.", {
            variant: "error",
          });
          return;
        }

        setLoading(true);

        // **Cálculo do total e da quantidade**
        let total = 0;
        let quantidadeTotal = 0;

        cursosAdicionados.forEach((curso) => {
          total += curso.preco * curso.quantidade;
          quantidadeTotal += curso.quantidade;
        });

        const formData = {
          nome,
          dataNasc: data_hoje,
          genero,
          email,
          telefone,
          endereco,
          cargo,
          instituicao,
          nacionalidade,
          area_atuacao: area,
          RUP: "",
          total,
          qtd: quantidadeTotal,
          cursos: JSON.stringify(cursosAdicionados), // O backend espera um array de objetos JSON
        };

        const response = await http.post("/registar-inscricao", formData);

        setLoading(false);

        enqueueSnackbar(
          `SUA ID INSCRIÇÃO É ESTA : ID-${response?.data?.inscricao?.id} - ${response?.data?.message}`,
          {
            variant: "success",
            persist: true, // Mantém o Snackbar na tela até o usuário fechar
            anchorOrigin: { vertical: "top", horizontal: "center" }, // Centraliza no topo
            action: (key) => (
              <button
                onClick={() => closeSnackbar(key)}
                style={{
                  color: "#fff",
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Fechar
              </button>
            ),
          }
        );

        // **Limpar o formulário após sucesso**
        setNome("");
        setTelefone("");
        setEmail("");
        setNacionalidade("");
        setArea("");
        setEndereco("");
        setDataHoje("");
        setInstituicao("");
        setCursosAdicionados([]); // Limpa os cursos adicionados
      } else {
        setLoading(false);
        enqueueSnackbar(
          "Por favor, preencha o nome e todos os outros campos obrigatórios corretamente.",
          { variant: "error" }
        );
      }
    } catch (error) {
      enqueueSnackbar(
        "Ocorreu um erro. Por favor, tente novamente mais tarde.",
        { variant: "error" }
      );
      setLoading(false);
    }
  };

  const isEmailValid = (email) => {
    // Expressão regular para validar e-mail
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const isDateValid = (date) => {
    const today = new Date();
    const selectedDate = new Date(date);
    return selectedDate < today;
  };

  const isTimeValid = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const selectedTime = new Date();
    selectedTime.setHours(hours, minutes, 0);
    const openingTime = new Date();
    openingTime.setHours(8, 0, 0);
    const closingTime = new Date();
    closingTime.setHours(17, 0, 0);
    return selectedTime >= openingTime && selectedTime <= closingTime;
  };

  return (
    <>
      <div className="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div className="card border-0 rounded shadow p-4 ms-xl-3">
          <div className="custom-form">
            <form>
              <div className="row">
                <div className="col-lg-8">
                  <div className="mb-3">
                    <label className="form-label">
                      {" "}
                      Nome <span className="text-danger"> * </span>
                    </label>
                    <input
                      name="name"
                      id="name1"
                      type="text"
                      className="form-control"
                      placeholder="Primeiro e ultimo nome"
                      value={nome}
                      onChange={(e) => setNome(e.target.value)}
                    />
                  </div>{" "}
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <label className="form-label">
                      {" "}
                      Data Nascimento <span className="text-danger"> * </span>
                    </label>
                    <input
                      name="name"
                      id="name1"
                      type="date"
                      className="form-control"
                      placeholder="data de nascimento"
                      value={data_hoje}
                      onChange={(e) => setDataHoje(e.target.value)}
                    />
                  </div>{" "}
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label"> Genero </label>{" "}
                    <select
                      onChange={(e) =>
                        handleSelectGenerorChange(e.target.value)
                      }
                      value={genero}
                      className="form-select form-control"
                    >
                      <option value={0}>Selecione o genero</option>
                      <option value={"Feminino"}>Feminino</option>
                      <option value={"Masculino"}>Masculino</option>
                    </select>{" "}
                  </div>{" "}
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label"> Nacionalidade </label>{" "}
                    <input
                      type="text"
                      className="form-control timepicker"
                      placeholder="Nacionalidade"
                      value={nacionalidade}
                      onChange={(e) => setNacionalidade(e.target.value)}
                    />{" "}
                  </div>{" "}
                </div>
                <hr></hr>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label">
                      {" "}
                      Seu E-mail <span className="text-danger"> * </span>
                    </label>
                    <input
                      name="email"
                      id="email"
                      type="email"
                      className="form-control"
                      placeholder="Seu E-mail :"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>{" "}
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label">
                      {" "}
                      Seu Telefone <span className="text-danger"> * </span>
                    </label>
                    <input
                      name="phone"
                      id="phone"
                      type="tel"
                      className="form-control"
                      placeholder="Seu telefone :"
                      value={telefone}
                      onChange={(e) => setTelefone(e.target.value)}
                    />
                  </div>{" "}
                </div>
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="form-label">
                      {" "}
                      Endereço <span className="text-danger"> * </span>
                    </label>
                    <input
                      name="endereco"
                      id="endereco"
                      type="text"
                      className="form-control"
                      placeholder="Seu endereço :"
                      value={endereco}
                      onChange={(e) => setEndereco(e.target.value)}
                    />
                  </div>{" "}
                </div>
                <hr></hr>
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="form-label">
                      {" "}
                      Área de Atuação/Especialidade{" "}
                      <span className="text-danger"> * </span>
                    </label>
                    <input
                      name="phone"
                      id="phone"
                      type="text"
                      className="form-control"
                      placeholder="Ex.: Medicina, Enfermagem, Farmácia, Estudante, etc."
                      value={area}
                      onChange={(e) => setArea(e.target.value)}
                    />
                  </div>{" "}
                </div>
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="form-label">
                      {" "}
                      Instituição de Trabalho/Ensino
                    </label>
                    <input
                      name="phone"
                      id="phone"
                      type="text"
                      className="form-control"
                      placeholder="Opcional"
                      value={instituicao}
                      onChange={(e) => setInstituicao(e.target.value)}
                    />
                  </div>{" "}
                </div>
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="form-label">
                      {" "}
                      Cargo/Função <span className="text-danger"> * </span>
                    </label>
                    <select
                      onChange={(e) => handleSelectFuncaoChange(e.target.value)}
                      value={cargo}
                      className="form-select form-control"
                    >
                      <option value={0}>Selecione</option>
                      <option value={"Médico(a)"}>Médico(a)</option>
                      <option value={"Enfermeiro(a)"}>Enfermeiro(a)</option>
                      <option value={"Estudante"}>Estudante</option>
                    </select>{" "}
                  </div>{" "}
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label">Abordagem</label>
                    <select
                      onChange={(e) => setCursoSelecionado(e.target.value)}
                      value={cursoSelecionado}
                      className="form-select form-control"
                    >
                      <option value={-1}>Selecione o curso</option>
                      {cursos.map((curso) => (
                        <option key={curso.id} value={curso.id}>
                          {curso.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="mb-3">
                    <label className="form-label">Quantidade</label>
                    <input
                      type="number"
                      className="form-control"
                      min="1"
                      value={quantidade}
                      onChange={(e) =>
                        setQuantidade(parseInt(e.target.value) || 1)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="mb-3">
                    <label className="form-label">Adicionar</label>
                    <button
                      type="button"
                      className="btn w-100"
                      style={{ background: "#03619d" }}
                      onClick={adicionarCurso}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="table-responsive shadow rounded">
                    <table className="table table-center table-bordered bg-white mb-0">
                      <thead>
                        <tr>
                          <th className="text-center py-4">Curso</th>
                          <th className="text-center py-4">Preço (Kz)</th>
                          <th className="text-center py-4">Quantidade</th>
                          <th className="text-center py-4">Subtotal</th>
                          <th className="text-center py-4">*</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cursosAdicionados.map((curso) => (
                          <tr key={curso.id}>
                            <td className="">{curso.nome}</td>
                            <td className="text-center">
                              {curso.preco.toLocaleString()} Kz
                            </td>
                            <td className="text-center">{curso.quantidade}</td>
                            <td className="text-center">
                              {(
                                curso.preco * curso.quantidade
                              ).toLocaleString()}{" "}
                              Kz
                            </td>
                            <td
                              className="text-center text-danger"
                              style={{ cursor: "pointer" }}
                              onClick={() => removerCurso(curso.id)}
                            >
                              X
                            </td>
                          </tr>
                        ))}

                        {cursosAdicionados.length > 0 && (
                          <tr>
                            <td colSpan={3}>
                              <strong>TOTAL</strong>
                            </td>
                            <td className="text-center">
                              <strong>
                                {cursosAdicionados
                                  .reduce(
                                    (total, curso) =>
                                      total + curso.preco * curso.quantidade,
                                    0
                                  )
                                  .toLocaleString()}{" "}
                                Kz
                              </strong>
                            </td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
 
                </div>
 
                <div className="col-lg-6 mt-4">
                  <div className="d-grid">
                    <span
                      id="submit"
                      name="send"
                      className="btn"
                      style={{ background: "#03619d" }}
                      onClick={() => handleInscrever()}
                    >
                      ENVIAR INSCRIÇÃO
                    </span>
                  </div>{" "}
                </div>{" "}
              </div>
            </form>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </>
  );
}
