/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import AuthUser from "../components/AuthUser";
import Spinner from "../components/Spinner";
import ModalImage from "react-modal-image"; // Importa o Lightbox

import { Link } from "react-router-dom";

import bg1 from "../assets/images/bg/fundo_jornada.jpg";
import post1 from "../assets/images/bg/post_jornada1.jpg";

import curso_pic1 from "../assets/images/bg/pic1.jpg";
import curso_pic2 from "../assets/images/bg/pic2.jpg";
import curso_pic3 from "../assets/images/bg/pic3.jpg";
import curso_pic4 from "../assets/images/bg/pic4.jpg";
import curso_pic5 from "../assets/images/bg/pic5.jpg";
import curso_pic6 from "../assets/images/bg/pic6.jpg";
import curso_pic7 from "../assets/images/bg/pic7.jpg";
import curso_pic8 from "../assets/images/bg/pic8.jpg";
import curso_pic9 from "../assets/images/bg/post_jornada1.jpg";

import about from "../assets/images/about/DoctorAubouUs.png";
import Navbar from "../components/navbar";
import { contact } from "../data/data";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";

import MarcarConsulta from "../components/MarcarConsulta";

import { SnackbarProvider, enqueueSnackbar, useSnackbar } from "notistack";

import {
  RiEyeFill,
  RiPsychotherapyFill,
  RiStethoscopeFill,
  RiCapsuleFill,
  RiMicroscopeFill,
  RiPulseFill,
  RiEmpathizeFill,
  RiMindMap,
  RiMentalHealthLine,
  RiAppleLine,
  RiInfraredThermometerLine,
  RiCapsuleLine,
  GrUserPolice,
  RiMicroscopeLine,
  TbAmbulance,
  RiShieldCrossLine,
  RiBriefcase4Line,
  FiAirplay,
  MdOutlineFlip,
  PiTelegramLogoBold,
  RiMailStarLine,
  RiHourglassLine,
  RiHeartLine,
  RiHeartPulseLine,
  RiStethoscopeLine,
  RiVirusLine,
  RiDossierLine,
  RiEye2Line,
  FiUser,
  FiMail,
  VscBook,
  MdFormatItalic,
  GiMedicalDrip,
  FiMapPin,
  FiBriefcase,
  LuFileCheck,
  MdOutlineStorefront,
  LuAward,
  LiaFileInvoiceDollarSolid,
  FiThumbsUp,
  FiPhone,
  RiArrowRightLine,
  RiMapPin2Line,
  FiFacebook,
  FiLinkedin,
  FiGithub,
  MdOutlineEventNote,
  FiCalendar,
  FiClock,
  FiHeart,
  FiEye,
  RiUser2Line,
  FiTwitter,
} from "./../assets/icons/vander";
import InscricaoJornadaCientifica from "../components/InscricaoJornadaCientifica";
import CtaTwo from "../components/cta/ctaTwo";
import AboutImage from "../components/aboutImage";

const icons = [
  { nome: "olhos", icon: RiEyeFill },
  { nome: "cabeça", icon: RiPsychotherapyFill },
  { nome: "ostetoscopio", icon: RiStethoscopeFill },
  { nome: "capsula", icon: RiCapsuleFill },
  { nome: "microscopio", icon: RiMicroscopeFill },
  { nome: "cardiologia", icon: RiPulseFill },
  { nome: "coracao", icon: RiEmpathizeFill },
  { nome: "celulas", icon: RiMindMap },
  { nome: "cerebro", icon: RiMentalHealthLine },
  { nome: "apple", icon: RiAppleLine },
  { nome: "fratura", icon: RiInfraredThermometerLine },
  { nome: "calendar", icon: FiCalendar },
  { nome: "doctor", icon: GrUserPolice },
  { nome: "telefone", icon: FiPhone },
  { nome: "email", icon: FiMail },
  { nome: "mapa", icon: FiMapPin },
];

const Ingresso = () => {
  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  // Cursos disponíveis
  const [cursos] = useState([
    {
      id: 1,
      nome: "Urgências e Emergências Urológicas",
      preco: 20000,
      preco_medico: 10000,
      preco_enfermeiro: 7000,
      preco_estudante: 5000,
      prelector: "Dra. –  Florinda  H. da S. Miranda e Siva",
      image: curso_pic1,
    },
    {
      id: 2,
      nome: "Suturas",
      preco: 25000,
      preco_medico: 10000,
      preco_enfermeiro: 7000,
      preco_estudante: 5000,
      prelector: "Dr. – Armando Lionjanga Horácio",
      image: curso_pic2,
    },
    {
      id: 3,
      nome: "Pé Boto",
      preco: 30000,
      preco_medico: 10000,
      preco_enfermeiro: 7000,
      preco_estudante: 5000,
      prelector: "Dr. – Nelson Geraldo",
      image: curso_pic3,
    },
    {
      id: 4,
      nome: "Imobilização",
      preco: 18000,
      preco_medico: 10000,
      preco_enfermeiro: 7000,
      preco_estudante: 5000,
      prelector: "Dr. – Nelson Geraldo",
      image: curso_pic4,
    },
    {
      id: 5,
      nome: "Suporte Básico de Vida",
      preco: 10000,
      preco_medico: 10000,
      preco_enfermeiro: 7000,
      preco_estudante: 5000,
      prelector: "Enfermeiro – João Paulo | Enfermeira – Patrícia Esteves",
      image: curso_pic5,
    },
    {
      id: 6,
      nome: "Acesso Venoso Central",
      preco: 10000,
      preco_medico: 10000,
      preco_enfermeiro: 7000,
      preco_estudante: 5000,
      prelector: "Dra. – Eunice Langa",
      image: curso_pic6,
    },
    {
      id: 7,
      nome: "Reanimação neonatal e cuidados ao recém-nascido",
      preco: 10000,
      preco_medico: 10000,
      preco_enfermeiro: 7000,
      preco_estudante: 5000,
      prelector: "Enfermeira – Hilcia Rosa H. M. Gonga",
      image: curso_pic7,
    },
    {
      id: 8,
      nome: "Actuação da Fisioterapia em paciente internado com AVC",
      preco: 10000,
      preco_medico: 10000,
      preco_enfermeiro: 7000,
      preco_estudante: 5000,
      prelector: "Dr. – Eduardo Francisco",
      image: curso_pic8,
    },
    {
      id: 9,
      nome: "Jornada 26,27 e 28 de Fevereiro",
      preco: 10000,
      preco_medico: 10000,
      preco_enfermeiro: 7000,
      preco_estudante: 5000,
      prelector: "",
      image: curso_pic9,
    },
  ]);

  const getIconByName = (name) => {
    const iconObject = icons.find((icon) => icon.nome == name);
    return iconObject ? iconObject.icon : null;
  };

  let IconeCalendar = getIconByName("calendar");

  const [email, setEmail] = useState("");
  //const [telefone, setTelefone] = useState('');
  const [nome, setNome] = useState("");
  const [assunto, setAssunto] = useState("");
  const [mensagem, setMensagem] = useState("");

  const [paragrafos, setParagrafos] = useState([]);

  const { http, url_root } = AuthUser();

  const [unidades, setUnidades] = useState([]);
  const [doctores, setDoctores] = useState([]);
  const [directores, setDiretores] = useState([]);

  const [bgBackup, setBG] = useState(bg1);
  const [info, setInfo] = useState([]);

  let Icon = getIconByName("ostetoscopio");
  let Icon2 = getIconByName("doctor");
  let Icon3 = getIconByName("microscopio");
  let Icon4 = getIconByName("cabeça");

  useEffect(() => {
    // Lógica para carregar cursos apenas se a permissão permitir

    const fetchData = async () => {
      try {
        // Chama a função assíncrona
        await getInfo();
      } catch (error) {
        setLoading(false);
        console.error("Erro ao obter info:", error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  useEffect(() => {
    console.log("Unidades:", unidades);
    setUnidades(info[0]?.unidades?.slice(0, 14) || []);
    setDoctores(info[0]?.doctores?.slice(0, 14) || []);
  }, [info]);

  const getInfo = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/site-pagina/10");

      console.log("info :");
      console.log(res.data);

      setInfo(res.data);

      console.log("Doctores");
      console.log(info[0]?.doctores);
      console.log("Consultas");
      console.log(info[0]?.consultas);

      setBG(url_root + "/storage/" + info[0]?.banner?.imagem);

      setUnidades(info[0]?.unidades?.slice(0, 14) || []);
      setDoctores(info[0]?.doctores?.slice(0, 14) || []);
      setDiretores(info[0]?.directores?.slice(0, 14) || []);
      setParagrafos(info[0]?.paragrafos || []);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleContactar = async () => {
    try {
      // Verificar se todos os campos estão preenchidos com mais de um caractere
      if (
        email.length > 1 &&
        nome.length > 1 &&
        assunto.length > 1 &&
        mensagem.length > 1
      ) {
        // Verificar se o e-mail é válido
        if (!isEmailValid(email)) {
          enqueueSnackbar("Por favor, forneça um e-mail válido.", {
            variant: "error",
          });
          return;
        }

        setLoading(true);
        const formData = new FormData();
        formData.append("titulo", assunto);
        formData.append("descricao", mensagem);
        formData.append("email", email);
        //formData.append('telefone', telefone);
        formData.append("nome", nome);

        const response = await http.post("/contactar-equalizador", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        console.log(response);

        setLoading(false);
        enqueueSnackbar("Recebemos o seu e-mail, muito obrigado.", {
          variant: "success",
        });

        setNome("");
        //setTelefone('');
        setEmail("");
        setAssunto("");
        setMensagem("");
      } else {
        enqueueSnackbar("Por favor, preencha todos os campos.", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(
        "Ocorreu um erro. Por favor, tente novamente mais tarde.",
        { variant: "error" }
      );
      setLoading(false);
    }
  };

  const isEmailValid = (email) => {
    // Expressão regular para validar e-mail
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-left nav-light"
        containerClass="container"
      />
      {info[0]?.banner != null ? (
        <>
          <section
            className="bg-half-170 d-table w-100"
            style={{
              backgroundImage: `url(${
                url_root + "/storage/" + info[0]?.banner?.imagem
              })`,
              backgroundPosition: "center",
            }}
          >
            <div className="bg-overlay bg-overlay-dark"></div>
            <div className="container">
              <div className="row mt-5 justify-content-center">
                <div className="col-12">
                  <div className="section-title text-center">
                    <h3 className="sub-title mb-4 text-white title-dark">
                      {" "}
                      {info[0]?.banner?.designacao}{" "}
                    </h3>
                    <p className="para-desc mx-auto text-white-50">
                      {info[0]?.banner?.descricao}{" "}
                    </p>

                    <nav
                      aria-label="breadcrumb"
                      className="d-inline-block mt-3"
                    >
                      <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                        <li className="breadcrumb-item">
                          <Link to="/">HRL</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Evento
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <></>
      )}
      <div className="container mt-5">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-6">
            <div className="section-title me-lg-5">
              {/* <span className="badge rounded-pill bg-soft-primary">
                {info[0]?.paragrafos[0]?.titulo}{" "}
              </span> */}

              <h4 className="title mt-3 mb-4">
                Rumo Á Excelência Na Prestação De Serviço De Saúde – Inscreva-se
                Agora!
              </h4>

              <p className="para-desc text-muted">
                Participe da nossa Jornada Científica e aprofunde seus
                conhecimentos com especialistas renomados da área da saúde. O
                evento contará com palestras, workshops e discussões inovadoras,
                proporcionando uma experiência enriquecedora para profissionais
                e estudantes.
              </p>

              <p className="para-desc text-muted">
                Garanta sua vaga preenchendo o formulário de inscrição no nosso
                website. Após o cadastro, você receberá um código de referência
                para envio do pagamento via WhatsApp ou e-mail. Não perca essa
                oportunidade de aprendizado e networking!
              </p>
            </div>
          </div>
          <AboutImage
            imagem={post1}
            videoId={"uuuuu"}
            colClass="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0"
          />
        </div>

        <hr className="mt-5"></hr>

        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="mb-4">
                  COMISSÃO CIENTÍFICA DAS Iª JORNADAS CIENTÍFICAS
                </h4>
                <p className="para-desc mx-auto text-muted mb-0">
                  MAPA DESCRITIVO DE PROPOSTAS DE CURSOS, MESAS REDONDAS, CASOS
                  CLÍNICOS…/ 2025
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="table-responsive shadow rounded">
                <table className="table table-center table-bordered mb-0">
                  {/* Cabeçalho */}
                  <thead
                    style={{ backgroundColor: "#03619d", color: "#ffffff" }}
                  >
                    <tr>
                      <th className="text-center py-4" colSpan={4}>
                        CURSOS PRÉ JORNADAS
                      </th>
                    </tr>
                  </thead>

                  <thead
                    style={{ backgroundColor: "#03619d", color: "#ffffff" }}
                  >
                    <tr>
                      <th className="text-center py-4">
                        <strong>N/O</strong>
                      </th>
                      <th className="text-center py-4">
                        <strong>Imagem</strong>
                      </th>
                      <th className="text-center py-4">
                        <strong>ABORDAGEM</strong>
                      </th>
                      <th className="text-center py-4">
                        <strong>PRELECTOR(A)</strong>
                      </th>
                    </tr>
                  </thead>

                  {/* Corpo da Tabela */}
                  <tbody>
                    {cursos
                      .filter((curso) => curso.id !== 9) // Ignora o curso de ID 9
                      .map((curso, index) => (
                        <tr
                          key={curso.id}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#f2f2f2" : "#ffffff",
                          }}
                        >
                          <td className="text-center">{curso.id}</td>
                          <td className="text-center">
                            <ModalImage
                              small={curso.image} // Imagem pequena
                              large={curso.image} // Imagem ampliada
                              alt={curso.nome} // Alt text
                              className="avatar avatar-md-sm rounded-pill shadow"
                            />
                          </td>
                          <td className="">{curso.nome}</td>
                          <td className="">{curso.prelector}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <hr className="mt-5"></hr>
      </div>
      <div className="container mt-5">
        <div className="row align-items-lg-end mb-5">
          <div className="col-md-6">
            <div className="me-xl-3">
              <div className="section-title mb-4 pb-2">
                <IconeCalendar className="h2" style={{ color: "#03619d" }} />
                <h4 className="title mb-4"> FICHA DE INSCRIÇÃO</h4>{" "}
                <p className="text-muted para-desc mb-0">
                  {" "}
                  As inscrições só estarão confirmadas após a recepção, via
                  watshap ou e-mail, do pagamento, juntamente com a ficha de
                  inscrição. Não haverá cancelamento de inscrições. Os valores
                  pagos poderão ser transferidos para outros cursos, por
                  impossibilidade de frequência, desde que comunicado com uma
                  antecedência de 10 dias.
                </p>{" "}
              </div>{" "}
              <div className="features feature-bg-secondary d-flex card flex-row p-4 rounded-md shadow position-relative overflow-hidden mt-4">
                <div className="ms-3">
                  <h5 className="titles">
                    {" "}
                    Os cursos pré Jornadas são realizados nos dias 24 e 25 de
                    Fevereiro de 2025{" "}
                  </h5>{" "}
                </div>{" "}
                <div className="big-icon"></div>{" "}
              </div>
              <div className="features feature-bg-secondary d-flex card flex-row p-4 rounded-md shadow position-relative overflow-hidden mt-4">
                <Icon
                  className="icons display-3 mb-0"
                  style={{ color: "#03619d" }}
                />
                <div className="ms-3">
                  <h5 className="titles"> Jornada 26, 27 e 28 De Fevereiro </h5>{" "}
                  <p className="text-muted para mb-0"> 5.000 Kwanzas </p>{" "}
                </div>{" "}
                <div className="big-icon">
                  <Icon />
                </div>{" "}
              </div>
              <div className="features feature-bg-secondary d-flex card flex-row p-4 rounded-md shadow position-relative overflow-hidden mt-4">
                <Icon2
                  className="icons display-3 mb-0"
                  style={{ color: "#03619d" }}
                />
                <div className="ms-3">
                  <h5 className="titles"> Curso Pré Jornada Para Médicos</h5>{" "}
                  <p className="text-muted para mb-0"> 10.000 Kwanzas </p>{" "}
                </div>{" "}
                <div className="big-icon">
                  <Icon2 />
                </div>{" "}
              </div>
              <div className="features feature-bg-secondary d-flex card flex-row p-4 rounded-md shadow position-relative overflow-hidden mt-4">
                <Icon3
                  className="icons display-3 mb-0"
                  style={{ color: "#03619d" }}
                />
                <div className="ms-3">
                  <h5 className="titles">Curso Pré Jornada Para Enfermeiros</h5>{" "}
                  <p className="text-muted para mb-0"> 7.000 Kwanzas </p>{" "}
                </div>{" "}
                <div className="big-icon">
                  <Icon3 />
                </div>{" "}
              </div>
              <div className="features feature-bg-secondary d-flex card flex-row p-4 rounded-md shadow position-relative overflow-hidden mt-4">
                <Icon4
                  className="icons display-3 mb-0"
                  style={{ color: "#03619d" }}
                />
                <div className="ms-3">
                  <h5 className="titles">Curso Pré Jornada Para Estudantes</h5>{" "}
                  <p className="text-muted para mb-0"> 5.000 Kwanzas </p>{" "}
                </div>{" "}
                <div className="big-icon">
                  <Icon4 />
                </div>{" "}
              </div>
            </div>{" "}
          </div>

          <InscricaoJornadaCientifica cursos={cursos} />
        </div>{" "}
      </div>{" "}
      <Footer footer={info[0]?.footer} consultas={info[0]?.consultas} />
      <ScrollTop />
      {loading ? (
        <>
          <Spinner />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default function WrappedFooter() {
  return (
    <SnackbarProvider>
      <Ingresso />
    </SnackbarProvider>
  );
}
